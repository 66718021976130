import React, {ReactChild, createContext, useContext, useEffect, useMemo, useState} from "react";

import {Config, setApiServiceAuthFailureHandler} from "@juniper/websdk-core";
import { newApiService } from "@juniper/websdk-core";

interface AuthContextType {
    user?: any;
    isAuthenticated: boolean;
    loading: boolean;
    login: (token: string) => void;
    logout: () => void;
}

interface Props {
  children: ReactChild,
  config: Config
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children, config }: Props) => {
    const [user, setUser] = useState<any>({});
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

    const apiService = newApiService(config);
    
    useEffect(() => {
        setApiServiceAuthFailureHandler(() => {
            logout();
        });
        
        const getSession = async () => {
            const user = await apiService.getSession();

            if (user) {
                setIsAuthenticated(true)
                setUser(user);
            }
            else {
                setIsAuthenticated(false);
                setUser(undefined);
            }
        };
        
        getSession()
            .catch(() =>{
                setIsAuthenticated(false);
                setUser(undefined);
            })
            .finally(() => setLoadingInitial(false));
    }, []);
    
    async function login(token: string) {
        setLoading(true);
        const loginResponse = await apiService.login(token);

        if (loginResponse) {
            setIsAuthenticated(true);

            setUser(loginResponse);
        }

        setLoading(false);
    }
    
    async function logout() {
        await apiService.logout();
        setIsAuthenticated(false);
        setUser(undefined);
    }

    // Make the provider update only when it should.
    // We only want to force re-renders if the user,
    // loading or error states change.
    //
    // Whenever the `value` passed into a provider changes,
    // the whole tree under the provider re-renders, and
    // that can be very costly! Even in this case, where
    // you only get re-renders when logging in and out
    // we want to keep things very performant.
    const memoValue = useMemo(
        () => ({
            user,
            isAuthenticated,
            loading,
            login,
            logout,
        }),
        [user, isAuthenticated, loading]
    );
    return (
        <AuthContext.Provider value={memoValue}>
            {!loadingInitial && children}
        </AuthContext.Provider>
    )
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export function useAuth() {
    return useContext(AuthContext);
}
