import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

export const Loading = () => {
    console.log('loading');
    return (
        <Box sx={{ display: 'flex' }}>
            <CircularProgress />
        </Box>
    );

}