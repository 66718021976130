import React, { ReactChild, createContext, useContext } from 'react';
import { useWeb3, Web3Client } from '../hooks/useWallet';
import { BrowserTracker } from '@scopely/web-sdk-analytics';

const web3InitialState: Web3Client = {
  provider: null,
  //web3Provider: null,
  address: '',
  network: null,
  connect: null,
  disconnect: null,
}

const Web3Context = createContext<Web3Client>(web3InitialState)

export interface ProviderProp {
  children: ReactChild,
  tracker: BrowserTracker
}

export const WalletProvider = ({ children, tracker }: ProviderProp) => {
  const web3ProviderState = useWeb3(tracker)

  return (
    <Web3Context.Provider value={web3ProviderState}>
      {children}
    </Web3Context.Provider>
  )
}

export function useWallet() {
  return useContext(Web3Context)
}