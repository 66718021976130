import { useState, useEffect, useCallback } from "react";
import Web3Modal from "web3modal";
import WalletConnectProvider from '@walletconnect/web3-provider';
//import { ethers } from 'ethers';
import { BrowserTracker } from '@scopely/web-sdk-analytics';

let providerOptions: any = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      appName: "Sandbox",
     //infuraId: "INFURA_KEY" //process.env.INFURA_KEY
      rpc: {
        1: 'https://rpc.ankr.com/eth',
        137: 'https://rpc.ankr.com/polygon', // 'https://polygon-rpc.com/',//
        1338: 'http://jsonrpc-dev.juniper.scopely.com:9545',
        1339: 'http://127.0.0.1:9545'
      },
      chainId: 1
    }
  },
};

let web3Modal:any;
if (typeof window !== 'undefined') {
  web3Modal = new Web3Modal({
    //network: 'mainnet', // optional
    cacheProvider: true,
    providerOptions, // required
    theme: "dark"
  })
}

function parseChainId(chainId: string) {
  return Number.parseInt(chainId, 16);
}

interface networkI {
  chainId:number, 
  name:string
}

export type Web3Client = {
  provider: any | null 
  //web3Provider: ethers.providers.Web3Provider | null | undefined
  address: string
  //network: ethers.providers.Network | null | undefined
  network: networkI | null | undefined
  connect: (() => Promise<void>) | null
  disconnect: (() => Promise<void>) | null
}

//@ts-ignore
export const useWeb3 = (tracker: BrowserTracker) => {

  //const [web3Provider, setWeb3Provider] = useState<ethers.providers.Web3Provider | null | undefined>(null);
  const [provider, setProvider] = useState<any | null>(null);
  const [address, setAddress] = useState<string>('');
  const [network, setNetwork] = useState<networkI | null | undefined>(null);

  const connect = useCallback(async function () {

    if (web3Modal) {
      try {
        console.log(' ');
        console.log('connect');
        const provider = await web3Modal.connect();
        
        // tracker.track("external_wallet_connect", {
        //   "type":"connect"
        // });

        // const web3Provider = new ethers.providers.Web3Provider(provider);

        // const userAddress = await web3Provider.getSigner().getAddress();
        // setAddress(userAddress);

        // const network = await web3Provider.getNetwork();
        // setNetwork(network)

        const chainId = await provider.request({ method: "eth_chainId" });
        const accounts = await provider.request({ method: "eth_requestAccounts" });

        setAddress(accounts[0]);
        setNetwork({
          chainId: parseChainId(chainId),
          "name": 'unknown'
        });

        setProvider(provider);
        //setWeb3Provider(web3Provider);
      } catch (e) {
        console.log('connect error', e)
      }
    } else {
      console.error('No Web3Modal')
    }
  }, []);

  const disconnect = useCallback(async () => {
    if (web3Modal) {
      web3Modal.clearCachedProvider()
      if (provider?.disconnect && typeof provider.disconnect === 'function') {
        await provider.disconnect()
      }

      setNetwork(null);
      setAddress('');
      setProvider(null);
      //setWeb3Provider(null);
    } else {
      console.error('No Web3Modal')
    }
  }, [provider]);

   // Auto connect to the cached provider
  useEffect(() => {
    if (web3Modal && web3Modal.cachedProvider) {
      connect()
    }
  }, [connect]);

   // EIP-1193 events
  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts: string[]) => {
        console.log('accountsChanged', accounts);

        setAddress(accounts[0]);
      }

      // https://docs.ethers.io/v5/concepts/best-practices/#best-practices--network-changes
      const handleChainChanged = (_hexChainId: string) => {
        if (typeof window !== 'undefined') {
          console.log('switched to chain...', _hexChainId)
          window.location.reload()
        } else {
          console.log('window is undefined')
        }
      }

      const handleDisconnect = (error: { code: number; message: string }) => {
        console.log('disconnect', error)
        disconnect()
      }

      provider.on('accountsChanged', handleAccountsChanged)
      provider.on('chainChanged', handleChainChanged)
      provider.on('disconnect', handleDisconnect)

      // Subscription Cleanup
      return () => {
        if (provider.removeListener) {
          provider.removeListener('accountsChanged', handleAccountsChanged)
          provider.removeListener('chainChanged', handleChainChanged)
          provider.removeListener('disconnect', handleDisconnect)
        }
      }
    }
  }, [provider, disconnect])

  return {
    provider,
    //web3Provider,
    address,
    network,
    connect,
    disconnect,
  } as Web3Client
}
