import { Config, newTrackerInstance } from "@juniper/websdk-core"

// Scopely Analytics ////////////

 // Titan API key identifies a single game.
 // https://developer.scopely.com/platform-integration/docs/web-sdk-analytics
const apiKey = "7768f83d-e310-40e4-8013-9be1381607bb"; // Juniper staging

export const trackerInstance = newTrackerInstance(apiKey);

// Scopely ID //////////////////

// Client id used to identify your application on the identity provider.
// https://developer.scopely.com/platform-integration/docs/scopely-id-1
const clientId = "0oa4msbtjt7d8diHt5d7";  // Juniper staging

const juniperApiConfigs : Record<string, {url: string; DappID: string;}> = {
    "localhost": {url: "http://localhost:4000", DappID: "com.scopely.sandbox.local"},
    "sandbox.dev.jnpr.io": {url: "https://api.dev.jnpr.io", DappID: "com.scopely.sandbox.development"},
}

export const config: Config = {
    scopelyIdentity: {
        redirectURL: `${window.location.origin}/scopelyid`,
        logoutURL:  `${window.location.origin}/scopelyid/logout`
    },
    juniperApi: getApiConfig(),
    clientId: clientId
}

function getApiConfig() {
    return juniperApiConfigs[window.location.hostname];
}