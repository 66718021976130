import React, { useEffect, useState } from 'react';
import { AuthProvider } from '@juniper/websdk-react-wallet-auth';
import { WalletProvider } from '@juniper/websdk-react';
import { BrowserTracker } from '@juniper/websdk-core';
import { config, trackerInstance } from './config/index';
import { Loading } from "./components/Loading";
import Dapp from './Dapp';

function App() {

    const [tracker, setTracker] = useState<BrowserTracker | null | undefined>(null);

    useEffect(() => {
        const getTracker = async () => {
            const res = await trackerInstance.getTracker();
            setTracker(res);
        }
        getTracker();
    }, []);

    return (
        tracker ? (
            <AuthProvider config={config}>
                <WalletProvider tracker={tracker}>
                    <Dapp tracker={tracker}/>
                </WalletProvider>
            </AuthProvider>
        ) : <Loading/>
    )
}

export default App;