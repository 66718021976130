import React, { useState, useEffect } from 'react';
import { Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Box } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { styled } from '@mui/material/styles';

import { useAuth } from '../hooks/useAuth';
import { useWallet } from '@juniper/websdk-react';

import ScopelyLogo from './scopely_s.png';

import {networkConfig} from '@juniper/websdk-core';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  //fontSize: 16,
  fontWeight:'700',
  color: '#000000',
  backgroundColor: '#f9da58',
  borderRadius: '0px',
  width:'100%',
  fontFamily: [
    'Roboto',
    'sans-serif'
  ].join(','),
  '&:hover': {
    backgroundColor: '#E3B344',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#1d1d1d',
    color: '#909090',
  },
  '&:disabled': {
    boxShadow: 'none',
    backgroundColor: '#333333',
    color: '#666666',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
  },
});

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiPaper-root': {
        padding: theme.spacing(4),
        backgroundImage: 'linear-gradient(to right, #282828 , #1a1a1a)',
        color: '#fff'
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(0),
        margin: `${theme.spacing(4)} 0`,
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(0),
        margin: theme.spacing(0),
    }

}));

interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
}

interface Error {
  code: number;
  error: string;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
       <Box
          aria-label="Scopely"
          sx={{
            position: 'relative',
            float:'right'
          }}
        >
            <img alt="Scopely logo" width='20px' src={ScopelyLogo}/>
        </Box>
    </DialogTitle>
  );
};

// leaving this here for now.
// interface chainProp {
//     id: string
//     token: string,
//     label: string,
//     nativeCurrency?: { name:string, symbol:string, decimals:number } | null,
//     rpcUrls: string[],
//     blockExplorerUrls:  string[] | null
// }

// interface injectedWallets {
//     name:string,
//     url:string
// }

// interface networkConfigProps {
//     default:chainProp,
//     chains: chainProp[],
//     appMetadata: {
//         name: string,
//         description: string,
//         icon: string,
//         recommendedInjectedWallets: injectedWallets[]
//     }
// }

export const SwitchNetworkDialog = (props: {desiredChainID: number}) => {

   const { provider, network } = useWallet();
   const { isAuthenticated } = useAuth();
   const [open, setOpen] = useState(false);

    const chain = networkConfig.chains.find(c => parseInt(c.id, 16) === props.desiredChainID);
    const desiredChainName = chain?.label ?? props.desiredChainID;
    
    const openDialogue = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSwitch = () => {
        setOpen(false);

        const switchNetwork = async () => {
             try {
              await provider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: chain?.id}] // "0x53A"  // chain?.id // "0x1"
              });
            console.log(`You have succefully switched to ${desiredChainName}`)
            } catch (switchError) {
              // This error code indicates that the chain has not been added to MetaMask.
              if ((switchError as Error).code === 4902) {
               try {
                await provider.request({
                   method: "wallet_addEthereumChain",
                   params: [
                     {
                        chainId: chain?.id,
                        chainName: desiredChainName,
                        nativeCurrency: chain?.nativeCurrency ? chain?.nativeCurrency : networkConfig.default.nativeCurrency,
                        rpcUrls: chain?.rpcUrls,
                        blockExplorerUrls: chain?.blockExplorerUrls,
                     }
                   ]
                 });
               } catch (addError) {
                 throw addError;
               }
              }
              console.log("Failed to switch to the network")
            }
        }

        switchNetwork();
    };

    useEffect(() => {
        if (!isAuthenticated || !network) return

        if (network?.chainId !== props.desiredChainID) {
            openDialogue();
        }
    }, [network, isAuthenticated]);

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="switchnetwork-dialog"
            open={open}
            TransitionComponent={Transition}
            keepMounted
        >
            <BootstrapDialogTitle id="switchnetwork-dialog">
                {`Switch to ${desiredChainName}?`}
            </BootstrapDialogTitle>
            <DialogContent>
                <Typography gutterBottom>
                    In order to mint, you must switch to the following network:
                </Typography>
                <Typography gutterBottom>
                    Chain ID: <strong>{props.desiredChainID}</strong>
                </Typography>
                <Typography gutterBottom>
                    RPC URL: <strong>{chain?.rpcUrls[0]}</strong>
                </Typography>
            </DialogContent>
            <DialogActions>
                 <BootstrapButton onClick={handleSwitch} variant="contained" disableRipple>Switch</BootstrapButton>
            </DialogActions>
        </BootstrapDialog>
    )
    return null;
}
