import React, {SetStateAction, useState} from "react";
import {Box, Card, CardContent, Grid, Tabs, Tab, ImageList, ImageListItem, ImageListItemBar, LinearProgress, Typography} from "@mui/material";
import ReactStars from 'react-stars';

function TabPanel(props: {children: any, index: number, value: number}) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3, width: '100%'}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export const TokensGrid = (props: {availableTokens: {tokens: any[], count: number}, ownedTokens: any[], allClaimedTokens: any[]}) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const handleTabChange = (event: any, newValue: SetStateAction<number>) => {
        setSelectedTabIndex(newValue);
    }
    
    return (
        <Card>
            <CardContent sx={{display: 'flex', justifyContent: 'left', flexGrow: 2}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                    <Tabs value={selectedTabIndex} onChange={handleTabChange}>
                        <Tab label={`Available (${props.availableTokens.count} / 3333) `} />
                        <Tab label="My Tokens" />
                        <Tab label="All Claimed Tokens" />
                    </Tabs>
                    <TabPanel index={0} value={selectedTabIndex}>
                        <Grid container spacing={1} >
                            <ImageList cols={5} gap={75}>
                                {props.availableTokens?.tokens?.map((item) => (
                                    <ImageListItem key={item._id}>
                                        <img
                                            src={`${item.metadata.image}?w=248&fit=crop&auto=format`}
                                            srcSet={`${item.metadata.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                            alt={item.metadata.name}
                                            loading="lazy"
                                        />
                                        <ImageListItemBar
                                            title={item.metadata.name}
                                            subtitle={
                                                    <React.Fragment>
                                                       <ReactStars
                                                            count={3}
                                                            value={item.metadata.properties.rarity}
                                                            size={24}
                                                            edit={false}
                                                        /> 
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Box sx={{ width: '100%', mr: 1 }}>
                                                                <LinearProgress variant="determinate" value={item.metadata.properties.intensity} />
                                                            </Box>
                                                            <Box sx={{ minWidth: 35 }}>
                                                                <Typography variant="body2" color="text.secondary">{item.metadata.properties.intensity}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </React.Fragment>
                                            }
                                            position="below"
                                        />
                                    </ImageListItem>
                                ))} 
                            </ImageList>
                        </Grid>
                    </TabPanel>
                    <TabPanel index={1} value={selectedTabIndex}>
                        <Grid container spacing={1} >
                            <ImageList cols={5} gap={75}>
                                {props.ownedTokens?.map((item) => (
                                    <ImageListItem key={item._id}>
                                        <img
                                            src={`${item.metadata.image}?w=248&fit=crop&auto=format`}
                                            srcSet={`${item.metadata.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                            alt={item.metadata.name}
                                            loading="lazy"
                                        />
                                        <ImageListItemBar
                                            title={item.metadata.name}
                                            subtitle={
                                                <React.Fragment>
                                                    <ReactStars
                                                        count={3}
                                                        value={item.metadata.properties.rarity}
                                                        size={24}
                                                        edit={false}
                                                    /> 
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Box sx={{ width: '100%', mr: 1 }}>
                                                            <LinearProgress variant="determinate" value={item.metadata.properties.intensity} />
                                                        </Box>
                                                        <Box sx={{ minWidth: 35 }}>
                                                            <Typography variant="body2" color="text.secondary">{item.metadata.properties.intensity}</Typography>
                                                        </Box>
                                                    </Box>
                                                </React.Fragment>
                                            }
                                            position="below"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Grid>

                    </TabPanel>

                    <TabPanel index={2} value={selectedTabIndex}>
                        <Grid container spacing={1} >
                            <ImageList cols={5} gap={75}>
                                {props.allClaimedTokens?.map((item) => (
                                    <ImageListItem key={item._id}>
                                        <img
                                            src={`${item.metadata.image}?w=248&fit=crop&auto=format`}
                                            srcSet={`${item.metadata.image}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                            alt={item.metadata.name}
                                            loading="lazy"
                                        />
                                        <ImageListItemBar
                                            title={item.metadata.name}
                                            subtitle={
                                                <React.Fragment>
                                                  <ReactStars
                                                        count={3}
                                                        value={item.metadata.properties.rarity}
                                                        size={24}
                                                        edit={false}
                                                    />
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <Box sx={{ width: '100%', mr: 1 }}>
                                                            <LinearProgress variant="determinate" value={item.metadata.properties.intensity} />
                                                        </Box>
                                                        <Box sx={{ minWidth: 35 }}>
                                                            <Typography variant="body2" color="text.secondary">{item.metadata.properties.intensity}</Typography>
                                                        </Box>
                                                    </Box>
                                                </React.Fragment>
                                            }
                                            position="below"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Grid>

                    </TabPanel>

                </Box>
            </CardContent>
        </Card>
    );
}