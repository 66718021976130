import React, { useEffect, useState } from 'react';
import { Container, AppBar, Toolbar, Box } from '@mui/material';
import { config } from './config/index';
import { newApiService } from "@juniper/websdk-core";
import { useAuth, ScopelyWeb3 } from '@juniper/websdk-react-wallet-auth';
import { useWallet } from '@juniper/websdk-react';
import { Onboarding } from "./components/Onboarding";
import { WalletStatus } from "./components/WalletStatus";
import { Loading } from "./components/Loading";
import { Mint } from './components/Mint';
import { TokensGrid } from "./components/TokensGrid";
import { BrowserTracker } from '@juniper/websdk-core';
import { ethers } from 'ethers';

function Dapp(props: {tracker: BrowserTracker}) {
  const { provider, address, network, disconnect } = useWallet();
  const [desiredChainId, setDesiredChainId] = useState(0);
  const [assigned, setAssigned] = useState(false);
  const {isAuthenticated, logout} = useAuth();
  
   const initialDataState: {loading: boolean, availableTokens: {tokens: any[], count: number} | null, ownedTokens: any[] | null, allClaimedTokens: any[] | null} =
      {
          loading: true,
          availableTokens: null,
          ownedTokens: null,
          allClaimedTokens: null
      }
  
  const [dataState, setDataState] = useState(initialDataState);
   
  const apiService = newApiService(config);

  useEffect(() => {
      if (!isAuthenticated) {
          return;
      }
      
      const fetchDapp = async () => {
          const dapp = await apiService.getDapp();
          setDesiredChainId(dapp.chainID);
      }

      fetchDapp();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
        if (!isAuthenticated) return

        let ignore = false;
        const fetchTokens = async () => {
            const availableTokens = await apiService.getAvailableTokens("SandboxPixie");
            const ownedTokens = await apiService.getOwnedTokens("SandboxPixie");
            const allClaimedTokens = await apiService.getAllClaimedTokens("SandboxPixie");

            if (!ignore) {
                setDataState({loading: false, availableTokens: availableTokens, ownedTokens: ownedTokens, allClaimedTokens: allClaimedTokens});
            }
        } 

        fetchTokens();

        return (() => { ignore = true; });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);
    
    useEffect(() => {
        const assignWallet = async () => {
            if (!isAuthenticated) return
            if (!provider) return
            if (network?.chainId !== desiredChainId) return
            
            const web3Provider = new ethers.providers.Web3Provider(provider);

            const message = `Welcome to Juniper! Please sign this message to link your ethereum address to your Scopely ID.\n\nAddress:\n${address}\n\nNonce:\n${Date.now()}`;

            const signer = await web3Provider.getSigner();
            const signature = await signer.signMessage(message);
            const addressString = await web3Provider.getSigner().getAddress();

            console.log(address);
            console.log(addressString);
            let response = await apiService.assignWallet(addressString, message, signature);
            if (response.status == 400) {
                console.log(response?.data.message);
                if (typeof disconnect === "function") {
                    disconnect();
                }
            }
            else {
                console.log(response)
                console.log('assigning wallet address: ', address);

                setAssigned(true);
            }
        }
        assignWallet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [provider, isAuthenticated]);

  return (
    <Container disableGutters maxWidth={false}>
      <AppBar position="sticky" sx={{bgcolor: "black"}}>
        <Box
          sx={{display: 'flex', alignItems: 'stretch', alignContent: 'stretch', flexWrap: 'nowrap', flexGrow: 1}}>
          <Toolbar sx={{flexGrow: 1}}>
            <Box sx={{
                display: 'flex',
                width: '100px',
                height: '60px',
                backgroundSize: 'contain',
                backgroundPosition: '0%',
                backgroundRepeat: 'no-repeat',
                backgroundImage: "url('/logo.png')",
                flexGrow: 1,
                my: 1
            }}>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'stretch', gap: 1}}>
              <ScopelyWeb3 desiredChainID={desiredChainId} config={config} tracker={props.tracker}/>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>

      <Box sx={{flexGrow: 1}}><WalletStatus/></Box>
    
      <Container sx={{mt: 1}}>
        {
            isAuthenticated && address && network?.chainId === desiredChainId && assigned ? (
                <React.Fragment>
                    <Mint/>
                    {!dataState.loading && dataState.availableTokens && dataState.ownedTokens && dataState.allClaimedTokens ? <TokensGrid availableTokens={dataState.availableTokens} ownedTokens={dataState.ownedTokens} allClaimedTokens={dataState.allClaimedTokens}/> : <Loading/>}
                </React.Fragment>
                
            ): <Onboarding desiredChainID={desiredChainId}/>
        }
      </Container>
    </Container>
  );
}

export default Dapp;
