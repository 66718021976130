import { styled } from '@mui/material/styles';
import { Box, Chip } from '@mui/material';
import { networkConfig } from '@juniper/websdk-core';
import { useWallet, ellipseAddress } from '@juniper/websdk-react';

const StatusItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const statusBoxStyles = {
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'wrap',
    p: 1,
    m: 0,
    listStyle: 'none',
    justifyContent: 'right'
};

export const WalletStatus = () => {
    const { address, network } = useWallet();

    const chainLabel = networkConfig.chains.find(c => parseInt(c.id, 16) === network?.chainId)?.label ?? network?.chainId;
    
    if (address) {
        return (
            <Box sx={ statusBoxStyles } component="ul">
                <StatusItem><Chip size="small" label="Wallet Connected" color="success"/></StatusItem>
                <StatusItem><Chip size="small" label={ellipseAddress(address)} color="primary"/></StatusItem>
                <StatusItem><Chip size="small" label={chainLabel} color="primary"/></StatusItem>
            </Box>
        );
    } else {
        return (
            <Box sx={ statusBoxStyles } component="ul">
                <StatusItem><Chip label="Wallet Not Connected" color="error"/></StatusItem>
            </Box>
        );
    }
}
