import React, {useState} from "react";
import { Button, Typography, Popover, Stack } from '@mui/material';
import { useWallet } from '@juniper/websdk-react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';



export const Web3Button = () => {
    const { provider, connect, disconnect } = useWallet();
    const [anchorEl, setAnchorEl] = useState(null);
    // <DisconnectButton disconnect={disconnect} />

    interface ConnectProps {
      connect: (() => Promise<void>) | null
    }
    const ConnectButton = ({ connect }: ConnectProps) => {
      return connect ? (
        <Button 
          disabled={false} 
          onClick={connect} 
          variant="outlined"
          color="inherit" 
        >
          Connect
        </Button>
      ) : (
        <Button 
          disabled={true} 
          variant="outlined"
          color="inherit" 
        >
          Loading...
        </Button>
      )
    }

    interface DisconnectProps {
      disconnect: (() => Promise<void>) | null
    }

    const handleDisconnect = () => {
          if (disconnect) disconnect();
          setAnchorEl(null);
      }

    const DisconnectButton = ({ disconnect }: DisconnectProps) => {
      return disconnect ? (
        <Button 
          disabled={false} 
          onClick={handleDisconnect} 
          variant="outlined"
          color="inherit" 
        >
          Disconnect
        </Button>
      ) : (
        <Button 
          disabled={true} 
          variant="outlined"
          color="inherit" 
        >
          Loading...
        </Button>
      )
    }

    

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'wallet-popover' : undefined;

    if (provider) {
      return (
        <div>
           <Button
                  size="large"
                  aria-label="account of current user"
                  variant="outlined"
                  aria-haspopup="true"
                  onClick={handleClick}
                  color="inherit" 
                  aria-describedby={id}
                  sx={{display: 'flex', flexDirection: 'row'}}
              >
             <Stack direction="row" spacing={1}>
                  <CheckCircleOutlineIcon />
                  <Typography>
                     Connnected                 
                  </Typography>
              </Stack>
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
             <DisconnectButton disconnect={disconnect} />
          </Popover>
        </div>
      )
    }
    else {
      return (
        <ConnectButton connect={connect} />
      )

    }

    return null;
}
