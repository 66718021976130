import React from 'react';
import { Container } from '@mui/material';
import Juniper_Image from '../assets/noun-juniper-faded.svg';
import {SwitchNetworkDialog} from '@juniper/websdk-react-wallet-auth';

export const Onboarding = (props: {desiredChainID: number}) => {

    return (

        <div>
            <Container maxWidth="sm">
                <img src={`${Juniper_Image}`} width="100%" alt="juniper berries"/>
            </Container>

            <SwitchNetworkDialog desiredChainID={props.desiredChainID}/>
        </div>
    )
    return null;
}
