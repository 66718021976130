import React, {useEffect, useState} from "react";
import {OktaProviderConfig, ScopelyId} from "@scopely/web-sdk-scopelyid";
import {Config as ScopelyIdConfig} from "@scopely/web-sdk-scopelyid/dist/src/settings";
import { BrowserTracker } from '@juniper/websdk-core';

import {Button, Typography, Popover, Stack} from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { Config } from "@juniper/websdk-core";

import { useAuth } from '../hooks/useAuth';
// import { useWallet } from '../context/providerContext';

import ScopelyLogo from './scopely_s.png';

export const ScopelySigninButton = (props: {showLogin: boolean, showLogout: boolean, config: Config, tracker: BrowserTracker }) => {
    // const { disconnect } = useWallet();

    const [anchorEl, setAnchorEl] = useState(null);

    const {user, isAuthenticated, login, logout} = useAuth();
    
    const identityProviderURL = "https://id.scopely.com/oauth2/default/v1/authorize";
    const redirectURL = props.config.scopelyIdentity.redirectURL;
    const logoutURL = props.config.scopelyIdentity.logoutURL;
    const providerConfig = new OktaProviderConfig(identityProviderURL, props.config.clientId, redirectURL, logoutURL);
    const scopelyIdCfg = new ScopelyIdConfig(providerConfig, "https://heimdall.aprod.scopely.io");
    // See Analytics section on Scopely Web SDK to know how to build the analytics tracker
    const scopelyId = new ScopelyId(scopelyIdCfg, props.tracker);
    
    useEffect(()=>{
        const authorize = async () => {
            let currentLocation = window.location.href;

            if (scopelyId.isAuthorizationUri(currentLocation)) {
                // Trigger UI feedback with "signing in", if needed.

                // You can access to the location on the application before being redirected to the identity provider
                // This is stored during the call to `login` that we shown on the previous section.
                const previousLocation = scopelyId.previousLocation()?.toString();

                if (previousLocation) {
                    window.history.pushState({}, "", previousLocation);
                }

                let loginResult = await scopelyId.authorizationResult(currentLocation);

                if (loginResult.isSuccessful()) {
                    // Scopely Id's access token. This should be treated as an opaque string to forward to the backend.
                    const token = loginResult.getResponse()?.getAccessToken();
                    
                    if (token) {
                        // Call to game's backend to link the access token with the current user (or any similar action).
                        
                        login(token);
                    }

                } else {
                    console.log("Errored during authorization with Scopely Id service: " + loginResult.getError());
                }
                
                // Trigger UI feedback with "signed in", if needed.
            }

            if (scopelyId.isLogoutUri(currentLocation)) {
                const previousLocation = scopelyId.previousLocation();
                if (previousLocation) {
                    window.history.pushState({}, "", previousLocation);
                } 

                let logoutResult = await scopelyId.logoutResult(currentLocation);

                if (logoutResult.isSuccessful()) {
                    console.log("State: " + logoutResult.getResponse()?.state);
                } else {
                    console.log(logoutResult.getError());
                }
            }
        }
        
        authorize()
            .catch(console.error);
        
    },[]) //notice the empty array here
    
    const handleLoginClick = async () => {
        await scopelyId.login();
    }
    const handleLogoutClick = async () => {
        logout();
        await scopelyId.logout();
        setAnchorEl(null);
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // const handleLoginClick = async () => {
    //     await scopelyId.login();
    // }
    
    // const handleLogoutClick = async () => {
    //     logout();
    //     await scopelyId.logout();
    //     setAnchorEl(null);
    // }

    // const handleDisconnectWalletClick = async () => {
    //     if (disconnect) {
    //         await disconnect();
    //     }
    //     setAnchorEl(null);
    // }

    // const handleMenu = (event: any) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    
    if (!isAuthenticated && props.showLogin) {
        return (
            <Button 
                color="inherit" 
                onClick={handleLoginClick}
                variant="outlined"
                startIcon={
                    <img alt="Scopely logo" width='20px' src={ScopelyLogo}/>
                }
            >
                Sign in
            </Button>
        )
    }
    else if (isAuthenticated && props.showLogout) {
        return (
            <div>
             <Button
                    size="large"
                    aria-label="account of current user"
                    variant="outlined"
                    aria-haspopup="true"
                    onClick={handleClick}
                    color="inherit" 
                    aria-describedby={id}
                   
                    sx={{display: 'flex', flexDirection: 'row'}}
                >
               <Stack direction="row" spacing={1}>
                    <AccountCircle />
                    <Typography>
                        {user?.userProfile.name}                        
                    </Typography>
                </Stack>
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
                <Button 
                    disabled={false} 
                    onClick={handleLogoutClick}
                    variant="outlined"
                    color="inherit" 
                >
                    Logout
                </Button>
            </Popover>
             </div>
        )
    }
    
    return null;
}