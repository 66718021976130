export const networkConfig = {
    default:{
        id: '0x1',
        token: 'ETH',
        label: 'Ethereum Mainnet',
        nativeCurrency: { name:'ETH', symbol:'ETH', decimals: 18 },
        rpcUrls: ['https://mainnet.infura.io/v3/'],
        blockExplorerUrls: ['https://etherscan.io']
    },
    chains: [
        {
            id: '0x53b',  // 1339
            token: 'ETH',
            label: 'Local Ganache',
            rpcUrls: ['http://127.0.0.1:9545'],
            blockExplorerUrls: null
        },
        {
            id: '0x53a', // 1338
            token: 'ETH',
            label: 'Juniper Devnet',
            rpcUrls: ['http://jsonrpc-dev.juniper.scopely.com:9545'],
            blockExplorerUrls: null
        },

        {
            id: '0x89', // 137
            token: 'MATIC',
            label: 'Polygon Mainnet',
            nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
            rpcUrls: [
                'https://polygon-rpc.com/',
                'https://rpc-mainnet.maticvigil.com',
                'https://polygon-mainnet.chainstacklabs.com',
                'https://rpc.ankr.com/polygon'
            ],
            blockExplorerUrls: ['https://polygonscan.com/']
        },
        {
            id: '0x13881', // 80001
            token: 'MATIC',
            label: 'Polygon Mumbai Testnet',
            nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
            rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
            blockExplorerUrls: [
                'https://mumbai.polygonscan.com/',
                'https://rpc.ankr.com/polygon_mumbai'
            ]
        },
    ],
    appMetadata: {
        name: 'Juniper Sandbox Local',
        description: 'Juniper Sandbox Local DAPP',
        icon: '/logo.png',
        recommendedInjectedWallets: [
            { name: 'MetaMask', url: 'https://metamask.io' }
        ]
    }
};