import axios, {AxiosInstance, AxiosRequestConfig, AxiosError} from 'axios';

export interface Config {
    scopelyIdentity: {
        redirectURL: string;
        logoutURL: string;
    };
    juniperApi: {
        url: string;
        DappID: string;
    },
    clientId: string
}

class ApiService {
    httpClient: AxiosInstance;
    config: Config;
    private static onAuthFailure: () => void;
    
    constructor(config: Config) {
        this.config = config;
        this.httpClient = axios.create({withCredentials: true});
        this.httpClient.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                if (error.response.status === 401 && ApiService.onAuthFailure) {
                    ApiService.onAuthFailure();
                }
                return Promise.reject(error);
            }
        )
    }
    
    async login(token: string) {
        try {
            const config = this.getConfig();
            config.headers = {
                ...config.headers,
                'x-scopely-id-token': token
            }
            
            const response = await this.httpClient.post('/auth/login', null, config);
            return response.data;
        } catch (error) {
            console.error(error);
        }        
    }

    async getSession() {
        try {
            const config = this.getConfig();
            const response = await this.httpClient.get('/auth/session', config);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    async logout() {
        try {
            const config = this.getConfig();

            const response = await this.httpClient.post('/auth/logout', null, config);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    async getDapp() {
        try {
            const config = this.getConfig();
            const response = await this.httpClient.get('/dapp', config);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    async assignWallet(walletAddress: string, message: string, signature: string) {
        try {
            const config = this.getConfig();
            const data = {
                message: message,
                signature: signature
            }

            const response = await this.httpClient.post('/assign/wallet/' + walletAddress, data, config);
            return response.data;
        } catch (error) {
            const err = error as AxiosError;
            return err.response
        }
    }

    async getAvailableTokens(contractName: string) {
        try {
            const config = this.getConfig();
            config.params = {contract: contractName};
            
            const response = await this.httpClient.get('/tokens/available', config);
            return response.data as {tokens: any[], count: number};
        } catch (error) {
            console.error(error);
            return {tokens: [], count: 0};
        }
    }

    async getOwnedTokens(contractName: string) {
        try {
            const config = this.getConfig();
            config.params = {contract: contractName};

            const response = await this.httpClient.get('/tokens/claimed', config);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    async getAllClaimedTokens(contractName: string) {
        try {
            const config = this.getConfig();
            config.params = {contract: contractName};

            const response = await this.httpClient.get('/tokens/claimed/all', config);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    static setAuthFailureHandler(onAuthFailure: () => void) {
        this.onAuthFailure = onAuthFailure;
    }
    
    private getConfig(): AxiosRequestConfig {
        let baseURL = this.config.juniperApi.url; 
        baseURL = baseURL + '/client';
        
        return {
            baseURL: baseURL,
            headers: {
                'Accept': 'application/json',
                'x-scopely-dapp-id': this.config.juniperApi.DappID,
            }
        }
    }
}

export function newApiService(config: Config) {
    return new ApiService(config);
}

export function setApiServiceAuthFailureHandler(onAuthFailure: () => void) {
    ApiService.setAuthFailureHandler(onAuthFailure);
}
