import React from "react";
import {Web3Button} from "./Web3Button";
import { ScopelySigninButton } from './ScopelySigninButton';
import { Config, BrowserTracker } from '@juniper/websdk-core';
import Stack from '@mui/material/Stack';

export const ScopelyWeb3 = (props: {desiredChainID: number, config: Config, tracker: BrowserTracker}) => {
    return (
        <div>
            <Stack direction="row" spacing={1}>
            <ScopelySigninButton showLogin={true} showLogout={true} config={props.config} tracker={props.tracker}/>
            <Web3Button />
            </Stack>
        </div>
    )


    // if (!isAuthenticated && !address) {
    //     // return (
    //     //     <SigninOnboarding tracker={props.tracker}/>
    //     // )
    //     return (
    //         <p>SigninOnboarding</p>
    //     )
    // }
    
    // if (isAuthenticated && (!address || network?.chainId !== props.desiredChainID)) {
    //     // return (
    //     //     <WalletOnboarding desiredChainID={props.desiredChainID} />
    //     // )
    //     return (
    //         <p>WalletOnboarding</p>
    //     )
    // }
}
