import React from "react";
import { Box, Button, Card, CardHeader, CardContent } from "@mui/material";
import { newApiService } from "@juniper/websdk-core";
import { config } from '../config/index';
import { useWallet } from '@juniper/websdk-react';
import { ethers } from 'ethers';

export const Mint = () => {

    const { provider } = useWallet();
    const apiService = newApiService(config);
    
    const handleMintClick = async () => {
        if (!provider) {
            return;
        }
        
        const response = await apiService.getDapp(); 

        const contract = response.contracts.find((c: { contractName: string; }) => c.contractName === 'SandboxPixie');

        if (contract) {

            const web3Provider = new ethers.providers.Web3Provider(provider);

            const contractInstance = new ethers.Contract(contract.contractAddress,  contract.abi, web3Provider.getSigner());
            await contractInstance.mint(1,{ value: ethers.utils.parseEther("0.08") });
        }
    }


    return (
        <Card>
            <CardHeader
                sx={{ pb: 0 }}
                titleTypographyProps={{ variant: "h5" }}
                title="Congratulations"
                subheader="You are ready to mint something"
            />
            <CardContent sx={{display: 'flex', justifyContent: 'center', flexGrow: 1}}>
                <Box>
                    <Button size="large" variant="contained" onClick={handleMintClick} >Giant Mint Button</Button>
                </Box>
            </CardContent>
        </Card>
    )

}