import {BrowserTracker, BuildType, Settings} from '@scopely/web-sdk-analytics';

class TrackerInstance {
    tracker: BrowserTracker | null;

    constructor(apiKey: string) {
        const settings = new Settings(apiKey, BuildType.Debug)
            .withPreview(true);

        const tracker = new BrowserTracker(settings);
        
        this.tracker = tracker;
    }

    async getTracker() {
        try {
            await this.tracker?.register();
            return this.tracker;
        } catch (error) {
            console.error(error);
        }        
    }
}

const newTrackerInstance = (apiKey: string) => {
    return new TrackerInstance(apiKey);
}

export {BrowserTracker, newTrackerInstance};